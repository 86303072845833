<template>
  <selected-filters
    :selected-list="selectedList"
    @resetData="resetData($event)"
  />
</template>
<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import DateFormatMixin from "@/mixins/DateFormatMixin";
  import SelectedFilters from '@/components/filterComponents/SelectedFilters';

  export default {
    name: "SelectedFiltersList",
    components: { SelectedFilters },
    mixins: [ DateFormatMixin ],

    computed: {
      ...mapState({
        filterModel: (state) => state.usersActivityModule.filterModel,
        usersActivityModule: (state) => state.usersActivityModule,
      }),

      selectedList() {
        return [
          {
            ifCondition: !!this.filterModel.search,
            boxText: '<b>Search</b>: ' + this.filterModel.search,
            resetArgument: 'search'
          },
          {
            ifCondition: this.filterModel.loginDate?.start && this.filterModel.loginDate?.end,
            boxText: `<b>Login Date</b>: ${this.getDate(this.filterModel.loginDate?.start)} - ${this.getDate(this.filterModel.loginDate?.end)}`,
            resetArgument: "loginDate",
          },
          {
            ifCondition: !!this.filterModel.loginStatus || this.filterModel.loginStatus===0,
            boxText: '<b>Login Status</b>: ' + this.getSelectedData('loginStatus'),
            resetArgument: 'loginStatus'
          },
          {
            ifCondition: !!this.filterModel.userStatus || this.filterModel.userStatus === 0,
            boxText: '<b>User Status</b>: ' + this.getSelectedData('userStatus'),
            resetArgument: 'userStatus'
          },
        ]
      }
    },

    methods: {
      ...mapActions({
        getUsersActivityList: 'usersActivityModule/getUsersActivityList'

      }),
      ...mapMutations({
        filterUsersActivity: 'usersActivityModule/filterUsersActivity'
      }),

      async resetData(type) {
        this.filterModel.resetCurrentData(type);
        this.filterUsersActivity()
        await this.getUsersActivityList(this.usersActivityModule.filterData)
      },

      getDate(date) {
        return this.$moment(date).format(this.ottDateFormat).valueOf();
      },

      getSelectedData(type) {
        switch (type) {
          case "loginStatus": {
            if (this.filterModel.loginStatus || this.filterModel.loginStatus === 0) {
              return this.usersActivityModule.loginStatusList.filter(
                (x) => x.value === this.filterModel.loginStatus
              )[0].name;
            }
            break;
          }
          case "userStatus": {
            if (this.filterModel.userStatus || this.filterModel.userStatus === 0) {
              return this.usersActivityModule.userStatusList.filter(
                (x) => x.value === this.filterModel.userStatus
              )[0].name;
            }
            break;
          }
        }
      },
    },
  };
</script>