import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "UsersActivity",

  data() {
    return {
      // isLoading: false,
      loading: false,
      defaultColumnConfigData: [],
      columnConfigData: [],
      selectedUser: null,
      warningModalBtnText: "",
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      warningModalType: "",
    };
  },

  computed: {
    ...mapState({
      filterModel: (state) => state.usersActivityModule.filterModel,
      usersActivityModule: (state) => state.usersActivityModule,

      // important variables vor get list and  change  columns
      filterData: (state) => state.usersActivityModule.filterData,
      filter: (state) => state.usersActivityModule.filter,
      dataTable: (state) => state.usersActivityModule.dataTable,
      defaultSettings: (state) => state.usersActivityModule.defaultSettings,
      updateColumnSettingsCommit: (state) =>
        state.usersActivityModule.updateColumnSettingsCommit,
      updateColumnsFromBack: (state) =>
        state.usersActivityModule.updateColumnsFromBack,
      isDefault: (state) => state.usersActivityModule.isDefault,
      auth: (state) => state.auth,
    }),

    filteredDataTable() {
      return this.dataTable.filter(item => item.email !== 'info@ottstream.live')
      /*
        This is just hard code to hide main user from list, because now there is not unic key to 
        hide main user, but if will be unic key you can filter with unic key for example this.dataTable.filter(item => item.type)
       */
      },
    
    defaultColumnConfigs() {
      return [
        {
          title: "Avatar",
          key: "avatar",
          defaultHide: true,
          defaultDragIndex: 0,
          mainIndex: 0,
          style: { minWidth: 90, maxWidth: 90 },
          isShow: true,
          isDrag: true,
          cellView: (row) => `<div class="P-user-page-image" style="background-image: url('
          ${
            row.avatar
              ? process.env.VUE_APP_API_URL + "files/icon/" + row.avatar
              : ""
          } ')"> ${!row.avatar ? `<span  class="icon-no-photos"></span>` : ""}</div>`,
        },
        {
          title: "ID",
          key: "id",
          defaultHide: true,
          defaultDragIndex: 1,
          mainIndex: 1,
          style: { minWidth: 220 },
          isShow: true,
          isDrag: true,
          isColumnClicked: false,
          cellView: (row) =>
            `<p class="G-text-table">${row.number} </p>`,
        },
        {
          title: "Full Name",
          key: "firstName",
          defaultHide: true,
          defaultDragIndex: 2,
          mainIndex: 2,
          style: { minWidth: 200 },
          isShow: true,
          isDrag: true,
          cellView: (row) =>
            `<p class="G-text-table">${row.firstname + " " + row.lastname} </p>`,
        },
        {
          title: "Role",
          key: "role",
          defaultHide: true,
          defaultDragIndex: 3,
          mainIndex: 3,
          style: { minWidth: 100 },
          isShow: true,
          isDrag: true,
          cellView: (row) =>
            `<p class="G-text-table">${this.getRolesData(row.rolesInfo)} </p>`,
        },
        {
          title: "Email Address",
          key: "email",
          defaultHide: true,
          defaultDragIndex: 4,
          mainIndex: 4,
          style: { minWidth: 220 },
          isShow: true,
          isDrag: true,
          cellView: (row) => `<p class="G-text-table">${row.email} </p>`,
        },
        {
          title: "Phone Number",
          key: "phoneNumber",
          defaultHide: true,
          defaultDragIndex: 5,
          mainIndex: 5,
          style: { minWidth: 200 },
          isShow: true,
          isDrag: true,
          cellView: (row) => `<p class="G-text-table">${row.phoneNumber} </p>`,
        },
        {
          title: "IP Address",
          key: "ipAddress",
          defaultHide: true,
          defaultDragIndex: 6,
          mainIndex: 6,
          style: { minWidth: 200 },
          isShow: true,
          isDrag: true,
          isColumnClicked: false,
          cellView: (row) =>
            `<p class="G-text-table">${this.getLastIP(
              row?.geoInfo
            )} </p>`,
        },
        {
          title: "GeoIP City",
          key: "city",
          defaultHide: true,
          defaultDragIndex: 7,
          mainIndex: 7,
          style: { minWidth: 200 },
          isShow: true,
          isDrag: true,
          isColumnClicked: false,
          cellView: (row) =>
            `<p class="G-text-table">${this.getCityName(
              row?.geoInfo
            )} </p>`,
        },
        {
          title: "GeoIP Country",
          key: "country",
          defaultHide: true,
          defaultDragIndex: 8,
          mainIndex: 8,
          style: { minWidth: 200 },
          isShow: true,
          isDrag: true,
          isColumnClicked: false,
          cellView: (row) =>
            `<p class="G-text-table">${this.getCountryName(
              row?.geoInfo
            )} </p>`,
        },
        {
          title: "Login Date/Time",
          key: "loginDateTime",
          defaultHide: true,
          defaultDragIndex: 9,
          mainIndex: 9,
          style: { minWidth: 200 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "loginDateTime",
            sortValue: "",
          },
          cellView: row => `<p class="G-text-table">${ row.loginDateTime !== undefined ? row.loginDateTime : '-' } </p>`
        },
        {
          title: "Logout Date/Time",
          key: "logoutDateTime",
          defaultHide: true,
          defaultDragIndex: 10,
          mainIndex: 10,
          style: { minWidth: 200 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "logOutDateTime",
            sortValue: "",
          },
          cellView: row => `<p class="G-text-table">${ row.logOutDateTime !== undefined ? row.logOutDateTime : '-' } </p>`
        },
        {
          title: "Duration (hh:mm:ss)",
          key: "duration",
          defaultHide: true,
          defaultDragIndex: 11,
          mainIndex: 11,
          style: { minWidth: 200 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "duration",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${row.duration ? row.duration : "-"} </p>`,
        },
        {
          title: "Login Status",
          key: "loginStatus",
          defaultHide: true,
          defaultDragIndex: 12,
          mainIndex: 12,
          style: { minWidth: 200 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "loginStatus",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${this.getLoginStatus(
              row.loginStatus
            )} </p>`,
        },
        {
          title: "User Current Status",
          key: "userStatus",
          defaultHide: true,
          defaultDragIndex: 13,
          mainIndex: 13,
          style: { minWidth: 200 },
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "userStatus",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.accessEnable ? "Enable" : "Disable"
            } </p>`,
        },
      ];
    },

    rowsPerPage: {
      set(limit) {
        this.setLimit(limit)
      },
      get() {
        return this.filter.limit;
      }
    },

    warningModalHeading() {
      return `${this.warningModalBtnText} Users(s) ?`;
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> one or more Users! If you're not sure, you can cancel this operation.`;
    },

    menuItemEnable() {
      let list = []

      if (this.permissionIs('deleteUser')) {
        list.push({title: "Delete", value: 'delete'})
      }

      if (this.permissionIs('updateUser')) {
        list.push(
          { title: "Edit", value: "edit" },
          { title: "Enable Access", value: "toggleAccess" },
        )
      }

      return list
    },

    menuItemDisable() {
      let list = []

      if (this.permissionIs('deleteUser')) {
        list.push({title: "Delete", value: 'delete'})
      }

      if (this.permissionIs('updateUser')) {
        list.push(
          { title: "Edit", value: "edit" },
          { title: "Disable Access", value: 'toggleAccess' }
        )
      }

      return list
    },

    menuMultipleItems() {
      let list = []

      if (this.permissionIs('deleteMultiply')) {
        list.push({ title: "Delete", value: 'delete' })
      }

      if (this.permissionIs('updateUser')) {
        list.push(
          { title: "Disable User", value: 'multipleDisable'},
          { title: "Enable User", value: 'multipleEnable'}
        )
      }

      return list
    },
  },

  async created() {
    this.loading = true;
    await this.getUserActivitySettings()
    this.createColumnConfigData()
    this.sortColumn();
    await this.updateUsersActivityList().finally(() => this.loading = false);
  },

  
  beforeDestroy() {
    this.filterModel.reset()
    this.setDataActivity({ filterData: {} })
  },

  destroyed() {
    this.setFilterPage(1)
  },

  methods: {
    ...mapActions({
      getUsersActivityList: "usersActivityModule/getUsersActivityList",
      getUserActivitySettings: "usersActivityModule/getUserActivitySettings",
      deleteUsers: "users/deleteUsers",
      updateUser: "users/updateUser",
      getUserById: "users/getUserById",
      enableDisable: "users/enableDisableUsers",
    }),
    ...mapMutations({
      setData: "users/setData",
      setLimit: "usersActivityModule/setLimit",
      setDataActivity: "usersActivityModule/setData",
      setFilterPage: "usersActivityModule/setFilterPage",
      sortColumn: "usersActivityModule/sortColumn",
      setDataSecondLevels: "users/setDataSecondLevels",
      generalInfoUserFetch: "generalInfoUser/fetchData",
      fetchDataRole: "roleUser/fetchDataRole",
      securityLoginFetchData: "securityLogin/fetchData",
      setUserId: "users/setUserId",
      openUsersModal: "users/openModal",
      disableSaveCloseButton: "users/disableSaveCloseButton",
      filterUsersActivity: "usersActivityModule/filterUsersActivity",
    }),
    async resetData() {
      this.filterModel.reset();
      this.filterUsersActivity();
      await this.getUsersActivityList(this.filterData);
      this.resetSelectedList()
    },

    async updateLimit() {
      await this.$store.dispatch(this.updateColumnsFromBack, this.defaultSettings).then(() => {
        this.createSaveDefaultData();
      })
      
      this.sortColumn();
      await this.getUsersActivityList(this.filterData);
      this.resetSelectedList()
    },

    /**
     * Update Users Activity list
     */
    async updateUsersActivityList(page) {
      if (page) {
        this.setFilterPage(page);
      }
      this.sortColumn();
      await this.getUsersActivityList(this.filterData);
      this.resetSelectedList()
    },

    filterActionsList(props) {
      if (props.id === this.auth.user.id) {
        return props.accessEnable
          ? this.menuItemDisable.filter((elem) => elem.value !== "delete")
          : this.menuItemEnable.filter((elem) => elem.value !== "delete");
      } else if (props.accessEnable) {
        return this.menuItemDisable;
      } else {
        return this.menuItemEnable;
      }
    },

    async sortColumnData(data) {
      this.sortColumn(data);
      await this.getUsersActivityList(this.filterData);
    },

    async selectAction() {
      const [event, data, actionIndex] = arguments;
      this.selectedUser = null;
      this.selectedUser = this.getDataFromActions(data);

      if (actionIndex === "delete") {
        this.warningModalBtnText = "Delete";
        this.warningModalIsDangerButton = true;
        this.openWarningModal(actionIndex);
      }
      if (actionIndex === "edit") {
        await this.getUserById(this.selectedUser.id).then((data) => {
          this.generalInfoUserFetch(data);
          this.fetchDataRole(data.rolesInfo);
          // this.$store.commit('roleUser/fetchData', data)
          this.securityLoginFetchData(data);
          this.openUsersModal();
          this.setData({
            userId: data.id,
            disableButton: false,
            disableFlag: true,
            showRoles: data.id === this.auth.user.id ? false : true,
          });
          this.disableSaveCloseButton();
        });
      }

      if (actionIndex === "toggleAccess") {
        let enableDisable = "";
        if (this.selectedUser.accessEnable) {
          enableDisable = "disable";
          this.warningModalIsDangerButton = true;
        } else {
          enableDisable = "enable";
          this.warningModalIsDangerButton = false;
        }

        this.warningModalBtnText = enableDisable;
        this.warningModalType = enableDisable;

        this.openWarningModal(enableDisable);
      }
    },
    async resetDataTableSettings() {
      await this.resetDataTable() 
      await this.getUserActivitySettings()
      this.createColumnConfigData()
    },

    getRolesData(roles) {
      let rolesData = "";
      if (roles) {
        for (let role in roles) {
          if (roles[role]) {
            rolesData += role.capitalize() + " </br>";
          }
        }
      } else {
        rolesData = "-";
      }

      return rolesData;
    },
    getCityName(geoInfo) {
      if (geoInfo && geoInfo?.city) {
        return geoInfo?.city?.names ? geoInfo?.city?.names?.en : geoInfo?.city;
      } else {
        return "-";
      }
    },
    getLastIP(geoInfo) {
      if (geoInfo && geoInfo?.realIp) {
        return geoInfo?.realIp;
      } else {
        return "-";
      }
    },

    getCountryName(geoInfo) {
      if (geoInfo && geoInfo?.country) {
        return geoInfo?.country?.names ? geoInfo?.country?.names?.en : "-";
      } else {
        return "-";
      }
    },
    getLoginStatus(status) {
      if (status || status === 0) {
        this.usersActivityModule.loginStatusList.filter(
          (x) => x.value === status
        )[0].name;
      }
      return "-";
    },
    getUserSTatus(status) {
      if (status || status === 0) {
        this.usersActivityModule.loginStatusList.filter(
          (x) => x.value === status
        )[0].name;
      }
      return "-";
    },
    openWarningModal(actionName) {
      this.warningModalType = actionName;
      this.warningModalIsLoading = false;
      switch (this.warningModalType) {
        case "delete":
          this.warningModalBtnText = "Delete";
          this.warningModalIsDangerButton = true;
          break;

        case "disable":
          this.warningModalBtnText = "Disable";
          this.warningModalIsDangerButton = true;
          break;

        case "enable":
          this.warningModalBtnText = "Enable";
          this.warningModalIsDangerButton = false;
          break;

        case "multipleDisable":
          this.warningModalBtnText = "Disable";
          this.warningModalIsDangerButton = true;
          break;

        case "multipleEnable":
          this.warningModalBtnText = "Enable";
          this.warningModalIsDangerButton = false;
          break;
      }

      this.setDataActivity({ showWarningModal: true });
    },

    closeWarningModal() {
      this.warningModalIsLoading = false;
      this.setDataActivity({ showWarningModal: false });
    },

    async warningModalBtnFunc() {
      await this.warningModalBtnFunction().finally(() => {
        this.closeWarningModal()
      });
    },

    async warningModalBtnFunction() {
      this.warningModalIsLoading = true;
      switch (this.warningModalType) {
        case "delete":
          await this.rejectUser();
          break;

        case "enable":
          this.setDataSecondLevels({
            toSaveData: { accessEnable: true },
          });
          await this.updateUser(this.selectedUser.id).then(async () => {
            await this.updateUsersActivityList();
          });
          break;

        case "disable":
          this.setDataSecondLevels({
            toSaveData: { accessEnable: false },
          });
          await this.updateUser(this.selectedUser.id).then(async () => {
            await this.updateUsersActivityList();
          });
          break;

        case "multipleDisable":
          this.selectedUser = null;

          if (this.selectedArrowList.length) {
            await this.enableDisableUsers(false);
          }
          break;

        case "multipleEnable":
          this.selectedUser = null;
          if (this.selectedArrowList.length) {
            await this.enableDisableUsers(true);
          }
          break;

        case "multipleDelete":
          this.selectedUser = null;
          await this.rejectUser();
          break;
      }
    },

    /**
     * Delete user, users
     * **/
    async rejectUser() {
      const deletedUserData = {
        userId: [],
      };

      if (this.isSelectedAll && !this.selectedUser) {
        deletedUserData.all = true
      }

      if (this.selectedUser) {
        deletedUserData.userId = [];
        deletedUserData.userId.push(this.selectedUser.id);

        await this.deleteUsers(deletedUserData)
          .then(() => {
            this.resetSelectedList();
          })
          .then(async () => {
            await this.updateUsersActivityList();
          });
        return;
      }

      if (this.selectedArrowList.length) {
        deletedUserData.userId = [];
        this.selectedArrowList.map((user) => {
          deletedUserData.userId.push(user.id);
        });

        await this.deleteUsers(deletedUserData)
          .then(() => {
            this.resetSelectedList();
          })
          .then(async () => {
            await this.updateUsersActivityList();
          });
        return;
      }
    },

    /**
     * Toggle access users
     * **/

    async enableDisableUsers(isEnable) {
      const data = {};

      if (!this.isSelectedAll && this.selectedArrowList.length) {
        this.selectedArrowList.map((user) => {
          data.userId.push(user.id);
        });
      }

      if (this.isSelectedAll) {
        data.all = true
      }

      if (isEnable) {
        data.accessEnable = true;
      } else {
        data.accessEnable = false;
      }

      await this.enableDisable(data).then(async () => {
        await this.updateUsersActivityList();
        this.resetSelectedList();
      });
    },

    async clickColumnDataTable(data, title) {
      if (title === "ID") {
        this.filterModel.id = data.number;
      }
      if (title === "IP Address") {
        if (data?.geoInfo) {
          this.filterModel.ipAddressesSearch = data.geoInfo?.realIp
        }
      }
      if (title === "GeoIP City") {
        if (data?.geoInfo) {
          this.filterModel.city = data.geoInfo?.city?.names
            ? data.geoInfo?.city?.names?.en
            : data.geoInfo?.city;
        }
      }
      if (title === "GeoIP Country") {
        if (data?.geoInfo) {
          this.filterModel.country = data.geoInfo?.country?.names
            ? data.geoInfo?.country?.names?.en
            : "-";
        }
      }
      this.filterUsersActivity();
      await this.getUsersActivityList(this.filterData);
      this.resetSelectedList()
      this.filterModel.getFilterCount();
    },

    async exportData() {
      this.filterUsersActivity()
      await this.getUsersActivityList({ ...this.filterData, excel: true })
      this.resetSelectedList()
    },
  },
};
