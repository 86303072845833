<template>
  <div class="P-filters">
    <div class="P-input-block">
      <ott-input
        v-model="filterModel.search"
        @timeoutInput="filterData('search')"
        :timeoutInput="true"
        label="Search"
        prependInnerIcon="mdi mdi-magnify  neutral--text"
      ></ott-input>
    </div>
    <div class="P-input-block G-date-picker G-flex G-align-center">
      <DatePicker
        v-model="filterModel.loginDate"
        :masks="{ input: ottDateFormat }"
        :max-date="newDateWithMainUserTZ"
        :popover="{ visibility: 'click' }"
        :is-dark="$vuetify.theme.dark"
        @input="filterData"
        is-range
        locale="en"
        class="ott-date-picker"
      >
        <template v-slot="{ inputValue, inputEvents }">
            <div class="ott-date-picker-cont Ott-input-class:">
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.start"
                        v-on="inputEvents.start"
                        outlined
                        dense
                        height="38"
                        label="Login Start"
                    />
                    <span
                        class="P-date-icon neutral--text"
                        @click="resetData('loginDate')"
                        :class="
                          !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                    ></span>
                </div>
                <span class="pick-to neutral--text">To</span>
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.end"
                        v-on="inputEvents.end"
                        outlined
                        dense
                        height="38px"
                        label="Login End"
                    />
                    <span
                        @click="resetData('loginDate')"
                        :class="
                            !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                        class="P-date-icon neutral--text"
                    ></span>
                </div>
            </div>
        </template>
      </DatePicker>
    </div>
    <div class="P-input-block">
      <ott-select
        label="Login status"
        :items="usersActivityModule.loginStatusList"
        :persistentHint="true"
        :clear="true"
        selected-text="name"
        v-model="filterModel.loginStatus"
        @emitFunc="filterData('loginStatus')"
      >
      </ott-select>
    </div>
    <div class="P-input-block">
      <ott-select
        label="User status"
        :items="usersActivityModule.userStatusList"
        :persistentHint="true"
        :clear="true"
        selected-text="name"
        v-model="filterModel.userStatus"
        @emitFunc="filterData('userStatus')"
      >
      </ott-select>
    </div>
    
    <selected-filters-list v-if="filterModel.filterCount.length" />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import DatePicker from "v-calendar/src/components/DatePicker";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttRangeSelect from "@/components/customComponents/OttRangeSelect";
import SelectedFiltersList from "./SelectedFiltersList";

export default {
  name: "FilterData",
  components: {
    OttRangeSelect,
    OttSelect,
    OttCheckbox,
    OttInput,
    DatePicker,
    SelectedFiltersList,
  },

  mixins: [DateFormatMixin, ConvertTimeZoneMixin],

  computed: {
    ...mapState({
      filterModel: (state) => state.usersActivityModule.filterModel,
      usersActivityModule: (state) => state.usersActivityModule,
      filter: (state) => state.usersActivityModule.filterData,
    }),
  },

  methods: {
    ...mapActions({
      getUsersActivityList: "usersActivityModule/getUsersActivityList",
    }),
    ...mapMutations({
      filterUsersActivity: "usersActivityModule/filterUsersActivity",
      setFilterPage: "usersActivityModule/setFilterPage",
      sortColumn: "usersActivityModule/sortColumn",
    }),

    /**
     * Get price group list
     * **/

    async filterData(type) {
      this.setFilterPage(1);
      this.filterUsersActivity();
      for (const property in this.filterModel) {
        if (
          (this.filterModel[type] === null ||
            (typeof this.filterModel[type] === "string" &&
              !this.filterModel[type])) &&
          type === property
        ) {
          this.filterModel.resetCurrentData(type);
        }
      }

      this.filterModel.getFilterCount();
      this.sortColumn();
      await this.getUsersActivityList(this.filter);
      this.$emit('resetSelectedList')
    },

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterModel.getFilterCount();
      this.filterUsersActivity();
      await this.getUsersActivityList(this.filter);
      this.$emit('resetSelectedList')
    },
  },
};
</script>
<style lang="scss" scoped>
.P-input-block {
  margin-bottom: 20px;
}

.P-ml-20 {
  margin-left: 20px;
}

.P-checkboxes {
  margin-bottom: 10px;
}

::v-deep {
  .v-text-field__details {
    display: none;
  }
  .v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0;
  }
}
</style>
