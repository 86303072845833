<template>
  <div class="P-client-payments">
    <div class="G-page-component" :class="{ 'P-filter-open': isOpenFilter }">
      <div class="G-page-title-block G-flex G-justify-between G-align-center">
        <div class="G-component-title">
          <h3 class="secondary--text">Users Activity</h3>
        </div>

      </div>
      <div class="G-component-header page-background">
        <div class="G-table-actions G-flex G-align-center">
          <div class="action-cont-first G-flex G-align-center">
            <OttCheckbox
              :indeterminate="selectNotAll"
              :dataVal="selectAllList"
              tooltip="Select all visibles"
              @changeCheckbox="selectAllVisibles"
            />
            <div class="G-select-all">
              <OttCheckbox
                tooltip="Select all"
                v-model="isSelectedAll"
                @changeCheckbox="selectAll"
              />
            </div>
            <div
                v-if="(selectedArrowList.length || selectedArrowListSub.length) && (permissionIs('updateUser') || permissionIs('deleteMultiply'))"
                class="approve-rej-icon-cont G-flex G-align-center"
            >
              <v-tooltip
                  v-if="permissionIs('updateUser')"
                  top
                  class="ott-tooltip"
                  color="tooltipBgColor"
                  attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                      class="P-settings"
                      v-bind="attrs"
                      v-on="on"
                      @click="openWarningModal('multipleDisable')"
                  >
                    <span class="mdi mdi-cancel neutral--text P-icon" />
                  </div>
                </template>
                <span>Disable User</span>
              </v-tooltip>
              <v-tooltip
                  v-if="permissionIs('updateUser')"
                  top
                  color="tooltipBgColor"
                  attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                      v-bind="attrs"
                      v-on="on"
                      class="P-settings"
                      @click="openWarningModal('multipleEnable')"
                  >
                    <span
                        class="mdi mdi-check-circle-outline neutral--text P-icon"
                    ></span>
                  </div>
                </template>
                <span>Enable User</span>
              </v-tooltip>
              <v-tooltip
                  v-if="permissionIs('deleteMultiply')"
                  top
                  color="tooltipBgColor"
                  attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                      class="P-settings"
                      v-bind="attrs"
                      v-on="on"
                      @click="openWarningModal('delete')"
                  >
                    <span class="mdi mdi-delete-outline neutral--text P-icon" />
                  </div>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
            <div
                v-if="(selectedArrowList.length || selectedArrowListSub.length) && permissionIs('updateUser')"
                class="ott-menu select-all-menu-cont P-settings"
            >
              <v-menu offset-y attach=".select-all-menu-cont">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      dark
                      icon
                      v-bind="attrs"
                      v-on="on"
                      class="P-settings"
                      :ripple="false"
                  >
                    <span
                      class="P-icon mdi mdi-dots-vertical G-flex-center"
                      :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}"
                    ></span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                      v-for="(item, key) of menuMultipleItems"
                      :key="key"
                      :ripple="false"
                  >
                    <v-list-item-title @click="openWarningModal(item.value)">{{
                        item.title
                      }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="G-excel-btn">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="P-settings" v-bind="attrs" v-on="on" @click="exportData">
                    <span class="mdi mdi-file-excel neutral--text P-icon" />
                  </div>
                </template>
                <span>Download Excel</span>
              </v-tooltip>
          </div>
          <OttHideShowColumnsOption
            :column-config="columnConfigData"
            :is-default="isDefault"
            class="P-users-columns"
            @updateColumnsFromCheck="updateColumnsFromCheck"
            @resetDataSettingsTable="resetDataTableSettings"
          />
        </div>
        <div>
          <div
            class="G-filter-btn"
            @click="toggleOpenFilter"
            :class="{ 'P-filter-open': isOpenFilter }"
          >
            <span class="mdi mdi-filter-variant"></span>
            <p>Filter</p>
            <div
              class="active-filter-count"
              v-if="filterModel.filterCount.length > 0"
            >
              <span>{{ filterModel.filterCount.length }}</span>
            </div>
            <span class="mdi mdi-menu-right"></span>
          </div>
        </div>
      </div>
      <div
        class="G-component-body page-background"
      >
        <div class="G-sub-block-title">
          <h3 class="secondary--text">User Activity List</h3>
        </div>
        <OttUniversalTable
          v-if="!loading"
          :column-config="columnConfigData"
          :selected-arrow-list="selectedArrowList"
          :data="filteredDataTable"
          :is-sub-table="false"
          :is-multi-select="true"
          :fixed-first-and-last-column="true"
          :main-data="filteredDataTable"
          :column-with-tree-increment="tableColumnWidth"
          :is-total-sum="false"
          @updateColumns="updateColumns"
          @saveColumnChangesFromDrag="updateColumnsFromDrag"
          @selectRow="selectRow"
          @sortData="sortColumnData"
          @updateColumnWith="updateColumn"
          @clickColumnData="clickColumnDataTable"
        >
          <template v-if="permissionIs('updateUser')" slot-scope="props" slot="action">
            <v-menu left content-class="P-actions-list">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    @click="selectAction($event, props, item.value)"
                    v-for="(item, i) in filterActionsList(props.rowData)"
                    :key="i"
                >
                  <div>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </OttUniversalTable>
        <OttPaginationForTable
            v-if="usersActivityModule.usersActivityResult && !loading"
            v-model="rowsPerPage"
            :data-count="usersActivityModule.dataCount"
            :available-data-count="filteredDataTable.length"
            :current-page="usersActivityModule.currentPage"
            :page-limit="usersActivityModule.totalPages"
            @update="updateLimit"
            @updatePage="updateUsersActivityList"
        />
        <NoDataInTable v-else />

      </div>
    </div>
    <div
      :class="{ 'P-filter-open': isOpenFilter }"
      class="G-filter-component page-background"
    >
      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData @resetSelectedList="resetSelectedList" />
      </div>
      <div class="G-filter-footer">
        <ott-button
          :click="resetData"
          class="primary-lg-btn"
          text="Reset Filters"
        >
        </ott-button>
      </div>
    </div>
    <PageLoader v-if="usersActivityModule.isTableLoading || loading"/>
    <WarningModal
      v-if="usersActivityModule.showWarningModal"
      :isModalOpen="usersActivityModule.showWarningModal"
      :modalHeading="warningModalHeading"
      :modalText="warningModalText"
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="setDataActivity({ showWarningModal: false })"
      @btnFunction="warningModalBtnFunc"
    />
  </div>
</template>

<script>
  import OttButton from "@/components/vuetifyComponents/OttButton";
  import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
  import OttHideShowColumnsOption from "@/components/OttUniversalTable/OttHideShowColumnsOption";
  import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
  import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
  import NoDataInTable from "@/components/NoDataInTable";
  import PageLoader from "@/components/pageLoader/PageLoader";
  import OttUniversalTableForComponentsMixin
    from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
  import FilterMixin from "@/mixins/FilterMixin";
  import ColumnConfigMixin from "@/mixins/ColumnConfigMixin";
  import FilterData from "./components/FilterData";
  import UsersActivityMixin from "./UsersActivityMixin";
  import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
  import OttRadio from "@/components/vuetifyComponents/OttRadio";
  import WarningModal from "@/components/WarningModal";

  export default {
    components: {
      WarningModal,
      OttRadio,
      OttSwitch,
      FilterData,
      PageLoader,
      NoDataInTable,
      OttPaginationForTable, OttUniversalTable, OttHideShowColumnsOption, OttCheckbox, OttButton
    },
    mixins: [UsersActivityMixin, OttUniversalTableForComponentsMixin, FilterMixin, ColumnConfigMixin]
  }
</script>

<style lang="scss">
@import "src/assets/scss/variables";

.P-user-page-image {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
  fill: #0a3c68;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-no-photos {
    color: $neutral-color;
    display: block;
    font-size: 40px;
  }
}
</style>

<style lang="scss" scoped>

  @import "src/assets/scss/variables";

  .P-users-columns {
    padding-left: 10px;
  }

  .P-filter-open {
    .mdi-menu-right {
      transform : rotate(180deg);
    }
  }

  .P-client-payments {
    position : relative;
  }

  .approve-rej-icon-cont {
    font-size : 24px;

    .mdi {
      padding : 0 7px;
      display : inline-block;
    }

    .mdi-cancel,
    .mdi-check-circle-outline {
      border-radius : $border-radius;
    }
  }

  .approve-rej-icon-cont::v-deep {
    .v-tooltip__content {
      border-radius : 6px;
      padding       : 7px 9px;
      font-size     : $txt12;
    }
  }

  .select-all-menu-cont {
    .P-icon {
      font-size : 26px !important;
    }

    ::v-deep {
      .v-menu__content {
        max-width : 190px !important;
      }
    }
  }

  .select-all-menu-cont button .mdi-dots-vertical,
  .settings-btn button .mdi-settings-outline {
    height        : 38px;
    font-size     : 24px;
    width         : 38px;
    border-radius : 4px;
  }

  .theme--light {
    .active-filter-count {
      background-color : #e5f5f6 !important;
      color            : $primary-color !important;
    }

    .action-cont-first-cont::v-deep {
      .v-icon {
        color : $neutral-color;
      }

      .mdi-minus-box {
        color : $primary-color !important;
      }
    }

    .select-all-menu-cont button[aria-expanded="true"] .mdi-dots-vertical,
    .settings-btn button[aria-expanded="true"] .mdi-settings-outline {
      background : $neutral-color15;
      color      : $secondary-color !important;
    }

    .select-all-menu-cont button .mdi-dots-vertical:hover,
    .settings-btn button .mdi-settings-outline:hover {
      background : $neutral-color15;
      color      : $secondary-color !important;
    }

    .icon-active {
      background    : $neutral-color;
      color         : $secondary-color !important;
      border-radius : $border-radius;
    }
  }

  .theme--dark {
    .action-cont-first-cont::v-deep {
      .v-icon {
        color : $neutral-colorDM !important;

        &.v-icon:after {
          opacity : 0;
        }
      }

      .mdi-minus-box {
        color : $primary-colorDM !important;
      }

      .primary--text {
        color : $primary-colorDM !important;
      }
    }

    .action-cont-first {
      border-right : 1.5px solid $borderDM;
    }
  }

  .G-table-actions {
    .v-menu__content {
      max-height : inherit !important;
    }
  }

  .v-menu__content.P-actions-list {
    max-height : inherit !important;
  }

  .G-excel-btn {
    border-left: unset;
    padding-left: unset;
  }

  .action-cont-first {
    display       : flex;
    border-right  : 1.5px solid $neutral-color;
    height        : 36px;
    align-items   : center;

    .v-input--selection-controls {
      margin-top : 23px;
    }
  }

  .P-icon {
    position   : relative;
    z-index    : 5;
    transition : $transition;
  }

  .P-settings {
    .P-icon {
      font-size : 22px;
    }
  }

  .P-bills-switch {
    margin       : 0 15px;
    padding      : 0 15px;
    border-left  : 1.5px solid $neutral-color;
    border-right : 1.5px solid $neutral-color;


    &::v-deep {
      .P-text {
        white-space : nowrap;
      }

      .v-input {
        width       : max-content !important;
        margin-top  : 0;
        padding: 10px 0;
      }
    }
  }
  .P-radio{
    margin-top: 0;
    padding: 10px 0;
  }
  .P-radio-box + .P-radio-box{
    margin-left: 15px;
  }


</style>
